@import "./color-style.scss";

.light-theme {
  .ant-layout-sider {
    background: $baseWhite;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .left-menu .ant-menu-item .ant-btn-link {
    color: rgba($light-theme-text-color, 0.6);
  }

  .left-menu {
    .ant-menu-item-selected {
      a {
        color: $baseBlack;
      }

      .ant-btn-link {
        color: $baseBlack;
      }
    }
  }

  .project-title {
    color: $light-theme-text-color;
  }

  //silder
  .flexslider {
    .rec-arrow {
      color: $light-theme-darkgray;
    }

    .rec-arrow-left {
      background: linear-gradient(267deg, #d6d6d600 0%, #e3e3e3de 100%) 0% 0% no-repeat padding-box;

      &.eWjCzc:hover:enabled,
      &.eWjCzc:focus:enabled,
      &.hJJzxj:hover:enabled,
      &.hJJzxj:focus:enabled {
        background: linear-gradient(267deg, #d6d6d600 0%, #e3e3e3de 100%) 0% 0% no-repeat padding-box;
        box-shadow: none;
        color: $light-theme-darkgray;
      }
    }

    .rec-arrow-right {
      background: linear-gradient(267deg, #e3e3e3de 0%, #d6d6d600 100%) 0% 0% no-repeat padding-box;

      &.eWjCzc:hover:enabled,
      &.eWjCzc:focus:enabled,
      &.hJJzxj:hover:enabled,
      &.hJJzxj:focus:enabled {
        background: linear-gradient(267deg, #e3e3e3de 0%, #d6d6d600 100%) 0% 0% no-repeat padding-box;
        box-shadow: none;
        color: $light-theme-darkgray;
      }
    }
  }

  .slides {
    .slides-items {
      background: $baseWhite 0% 0% no-repeat padding-box;
      box-shadow: none;
      color: $light-theme-text-color;

      &.active {
        background: $baseRed 0% 0% no-repeat padding-box;
        color: $baseWhite;
      }
    }
  }

  //project details
  .project-details-wrapper {
    color: $light-theme-text-color;

    // color:  $baseWhite;
    .title {
      color: $light-theme-text-color;

      .anticon-close {
        color: $light-theme-text-color;
        cursor: pointer;
      }
    }
  }

  // tab
  .custom-tab {
    .ant-tabs-nav {
      background: rgb(255, 255, 255) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }

    .ant-tabs-tab {
      color: $light-theme-text-color;
    }

    // .ant-tabs-tab-active {
    //   background: $light-theme-darkgray;
    //   .ant-tabs-tab-btn {
    //     color: $baseRed;
    //   }
    // }
    .ant-tabs-tab-btn:active {
      color: $baseRed;
    }
  }

  .custom-line {
    background: $light-theme-border-color;
  }

  .border-right {
    border-color: $light-theme-border-color;
  }

  hr {
    color: $light-theme-text-color;
  }

  h4.ant-typography,
  .ant-typography h4 {
    color: $light-theme-text-color !important;
  }

  .ant-typography {
    color: $light-theme-text-color !important; // !important;
  }
  .custom-card{
    background: $baseWhite 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
   color:#EF314C;

    .ant-card-head 
    {
      color: $light-theme-text-color;
    }
    .ant-checkbox-wrapper
    {
      color: $light-theme-text-color;
    }
    .anticon {
      color: $baseRed;
      font-size: 30px;
      border-radius: 25px;
      border: 1px solid;
      margin: 0 10px;

      &:hover {
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  

  //card
  .custom-card2.custom-card3 {
    background: $baseWhite 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    color: $light-theme-text-color;

    .ant-card-head 
    {
      color: $light-theme-text-color;
    }
    .ant-checkbox-wrapper
    {
      color: $light-theme-text-color;
    }
    .anticon {
      color: $baseWhite;
      font-size: 30px;
      border-radius: 25px;
      border: 1px solid;
      margin: 0 10px;

      &:hover {
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  .Cashflow-custom-card {
    background: $baseWhite 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    color: $light-theme-text-color;

    .ant-card-head 
    {
      color: $light-theme-text-color;
    }
    .ant-checkbox-wrapper{
      color: $light-theme-text-color;
    }
 
  
  }
  .Tracker-modal
  {
    .ant-checkbox-wrapper
    {
      color: $light-theme-text-color;
    }
    

  }
  .ant-drawer-content{

    color: $light-theme-text-color;
    background: $baseWhite 0% 0% no-repeat padding-box;
  }
  .ant-slider-mark-text{
    color: $light-theme-text-color;
  }
  .equipment-card{
    color: $light-theme-text-color;
  }

  .equipment-row {
    color: $light-theme-text-color;
  }
.pricing-updateFrom{
  color: $light-theme-text-color;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: $light-theme-text-color;
  }
  
  
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  color: $light-theme-text-color;
}
.project-detail-collapse{
  color: $light-theme-text-color;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: $light-theme-text-color;
  }
}

.ant-input-group-addon{
  color: $light-theme-text-color;
  border: 1px solid rgba($light-theme-text-color, 0.2);
}
.Data360-addbefore .Data360-addbefore1{
  .ant-input-group-addon{
    color: $light-theme-text-color;
    border: 1px solid rgba($light-theme-text-color, 0.2);

   // background-color: #FFFFFF1A !important;
    //border-radius:'20px' !important;
    border-bottom-left-radius:20px !important;
    border-top-left-radius:20px !important
  
  }
  .ant-input-group .ant-input{
    text-align: center;
  }
}

  .ant-table-cell,
  .export-link {
    color: $light-theme-text-color;
  }

  .ant-modal-content {
    backdrop-filter: none;
    background: #F4F5FE;
  }

  .report-table {
    .ant-table-thead {
      background: #F4F5FE;
    }
  }

  .user-name {
    p {
      color: $baseBlack;
    }

    .ant-avatar {
      background-color: $light-theme-border-color;
    }
  }

  //modal
 
    .searchEquip {
      margin: 0 0px;

      .ant-input-affix-wrapper {
        background: #FFFFFF1A !important;
        border-radius: 20px;
        border: 1px solid rgba($light-theme-text-color, 0.2);

        .ant-input {
          background: transparent !important;
          border-radius: 0 !important;
          border: none !important;
        }

        .anticon {
          color: $baseRed;
        }
      }
    }
  
  .ant-modal {
    .ant-modal-footer {
      .ant-btn {
        color: $light-theme-text-color;
        border-color: $light-theme-text-color;
      }
    }

    .ant-table-thead {
      font-size: $font-size-base;

      tr {
        th {
          background: transparent !important;
          border-bottom: none !important;

          &.ant-table-cell-scrollbar {
            box-shadow: none;
          }
        }

        .main-title {
          background: rgba($light-theme-text-color, 0.8) 0% 0% no-repeat padding-box !important;
        }
      }
    }

  }

  .ant-modal-header {
    border-color: $light-theme-border-color;

    .ant-modal-title {
      color: $light-theme-text-color;
    }
  }

  //table
  .ant-table {
    color: $light-theme-text-color;
  }

  .ant-table-tbody {
    tr.ant-table-row {
      &:nth-child(even) {
        background: rgba($baseWhite, 0.6) !important;
      }
    }
  }

  //scroll bar

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(197, 197, 197, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(209, 209, 209);
    outline: 1px solid rgb(223, 222, 222);
  }

  //button
  .refresh {
    background: transparent;
    color: $light-theme-text-color;
    border-color: $light-theme-text-color;

    &:hover,
    &:focus,
    &:active {
      color: $light-theme-text-color;
      border-color: $light-theme-text-color;
    }
  }

  .uploadFile_admin {
    .ant-upload {
        display: block;
        text-align: center;

        .ant-btn {
            width: 400px;
            margin: 10px;
            height: 100px;
            border: 1px dashed;
            border-radius: 12px;
            color: $light-theme-text-color;
            &:hover,
            &:active,
            &:focus {
                color: gray;
            }
        }
    }

    .ant-upload-list-item {
        display: block;
        text-align: center;
        width: 400px;
        margin: 10px 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 14px 10px;
        border-radius: 25px;
        background:  #EF314C;

        .ant-upload-text-icon .anticon {
            position: relative;
            top: -2px;
        }

     .ant-upload-list-item-card-actions-btn {
        opacity: 1;
     }
    }
}

  //datepicker
  .ant-picker-panel-container {
    background: $baseWhite;
  }

  .ant-picker-header {
    color: $light-theme-text-color;
    border-color: $light-theme-border-color;

    button {
      color: rgba($light-theme-text-color, 0.6);

      &:hover {
        color: rgba($light-theme-text-color, 0.9);
      }
    }

    .ant-picker-header-view {
      button {
        color: $light-theme-text-color;
      }
    }
  }

  .ant-picker-content {
    th {
      color: rgba($light-theme-text-color, 0.8);
    }
  }

  .ant-picker-cell {
    color: rgba($light-theme-text-color, 0.6);
  }

  .ant-picker-cell-in-view {
    color: $light-theme-text-color;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: $light-theme-text-color;
    background: $baseRed;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $baseRed;
  }

  .ant-picker-today-btn {
    color: $light-theme-text-color;
  }

  .ant-picker-panel {
    border: none;

    .ant-picker-footer {
      border-color: $light-theme-border-color;
    }
  }
  .ant-select-arrow,
  .ant-select-selection-item{
    color: $baseBlack;
  }

  .ant-form-item-label>label {
    color: $light-theme-text-color;
  }

  //input
  .ant-select-selection-item {
    color: $light-theme-text-color;
  }

  .ant-select-item-option-content {
    color: $light-theme-text-color;
  }

  .ant-input {
    color: $light-theme-text-color;
    border: 1px solid $light-theme-border-color !important;
  }

  .ant-input:focus,
  .ant-input:hover,
  .ant-input-focused {
    border: 1px solid $light-theme-border-color !important;
  }

  .ant-select-dropdown {
    background: $baseWhite;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: $baseRed;
    color: $light-theme-text-color;
  }

  ::placeholder {
    color: rgba($light-theme-text-color, 0.6);
  }

  .edit-session {
    .ant-picker {
      border: 1px solid $light-theme-border-color !important;
      border-right: none !important;
    }

    .ant-picker-suffix {
      color: grey;
    }

    ::placeholder {
      color: grey;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: grey;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: grey;
    }
  }

  .ant-picker-input>input {
    color: $light-theme-text-color;
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 2px rgba($baseRed, 0.2);
  }

  .top-bar-input {
    color: $light-theme-text-color;
    background: rgba($light-theme-text-color, 0.2);
  }

  .filter-none {
    color: $light-theme-text-color;
    background: rgba($light-theme-text-color, 0.2);
  }

  .ant-radio-wrapper {
    color: $light-theme-text-color;
  }

  .ant-radio-disabled+span {
    color: $light-theme-text-color;
  }

  .ant-avatar {
    background: rgba($light-theme-text-color, 0.2) !important;
  }

  .ant-layout-sider-trigger {
    color: $light-theme-text-color;
    background: transparent linear-gradient(180deg, #00000000 0%, #00000036 100%) 0% 0% no-repeat padding-box;
  }

  td .theme-color {
    color: $light-theme-text-color !important;
  }

  .poweredby {
    p {
      color: $light-theme-text-color;
    }
  }

  //coming soon
  .coming-soon {
    background: rgba($light-theme-text-color, 0.3);

    h1 {
      color: $light-theme-text-color;
    }

    p {
      color: $light-theme-text-color;

    }
  }

  .ant-empty-normal .ant-empty-image {
    opacity: 0.3;
  }

  .ant-empty-description {
    color: $light-theme-text-color;
  }

  .login_copy {
    position: absolute;
    color: #111b26;
    font-size: 14px;
    font-weight: 500;
    bottom: 10px;
    text-align: center;

    a {
      color: #111b26;
    }
  }

  // login
  .login-form {

    h1 {
      color: $baseBlack;
    }

    .login-form-forgot {
      color: $baseBlack;
    }

    .ant-input {
      height: 50px;
      padding: 6.5px 18px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.2);
    }

    .login-form-button {
      height: 50px;
      color: $baseWhite;
      background: $baseRed;
      border-color: $baseRed;
      font-weight: 700;
      font-size: $font-size-xxl;

      &.ant-btn:hover,
      &.ant-btn:focus,
      &.ant-btn:active {
        color: $baseBlack;
        border-color: $baseRed;
        background: transparent;
      }
    }

    .resendBtn {
      width: 37% !important;
      font-size: 14px !important;
      height: 20px !important;
      border: 0 !important;
      margin: 0 !important;
      color: #ef314c;
      padding-top: 0 !important;
      padding-left: 5px !important;
      margin-left: 10px !important;
      font-weight: 500 !important;
      background: none !important;

      &:hover {
        background: none !important;
        border: 0 !important;
        color: $light-theme-text-color !important;
      }
    }

    .anticon {
      color: $light-theme-text-color;
    }

    .forgotPswdForm {
      .ant-form-item-feedback-icon-success {
        color: #49aa19;

        .anticon {
          color: #49aa19;
        }
      }

      .ant-form-item-feedback-icon-warning {
        color: #d89614;

        .anticon {
          color: #d89614;
        }
      }

      .verificationCode {
        input {
          border: 1px solid $light-theme-text-color;
          color: $light-theme-text-color;
        }
      }

      .sperator {
        color: $light-theme-text-color;
      }
    }
  }

  .login-form-signup_text {
    color: $light-theme-text-color !important;
  }

  // .logo-wrapper {
  //   .aside-logo-large {
  //     // .logo-black {
  //     //   display: block;
  //     //   margin-left: 40px;
  //     // }

  //     // .logo-white {
  //     //   display: none;
  //     // }
  //   }
  // }
  .Data360-addafter  .Data360-addafter1{
    .ant-input-group-addon {
      border: 1px solid $light-theme-border-color !important;
    
      background-color: transparent!important;
      //border-radius:'20px' !important;
      border-bottom-Right-radius: 20px !important;
      border-top-Right-radius: 20px !important
    }
  }

  .client-select {
    .ant-select-selector {
      border: 1px solid $light-theme-border-color !important;
    }
  }


  .bevel-select  {
    .ant-select-selector {
      border: 1px solid $light-theme-border-color !important;
    }
  }

  .date-picker {
    .ant-picker {
      border: 1px solid $light-theme-border-color !important;
    }

    .ant-picker-suffix {
      color: grey;
    }

    ::placeholder {
      color: grey;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: grey;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: grey;
    }

  }

  .statement_work,
  .logDetails_table {

    .ant-table-placeholder {
      background: transparent !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        background: transparent !important;
      }

      .ant-table-cell {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: transparent !important;

        // z-index: 1000;
        &:hover {
          background: transparent !important;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

    }

    tr:nth-child(even) {
      td:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #fbfbff !important;
        // z-index: 1000;
      }

      &:hover>td:last-child // th:last-child 

        {
        background: #fbfbff !important;
      }
    }

    tr:nth-child(odd) {
      td:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #f4d7e3 !important;
        // z-index: 1000;
      }

      &:hover>td:last-child // th:last-child 

        {
        background: #f4d7e3 !important;
      }
    }

    .ant-table-tbody tr.ant-table-row:nth-child(odd) {
      background: #f4d7e3 !important;
    }

    tr:first-child {
      td:last-child {
        background-color: transparent !important;
      }
    }
  }

  //pricing collapse
.collapsible-table {
  background-color: transparent; 
  box-shadow: 0px 4px 8px rgba(251, 249, 249, 0.1); 

  .ant-collapse {
    background-color: white; 

    .ant-collapse-header {
      color: $baseBlack; 
      background-color: #F4F5FE; 
      
      &:hover {
        background-color: rgb(211, 209, 209); 
      }
    }
    .ant-collapse-content {
      background-color: transparent; 
    }
  }
}
//proposal card
.proposal-list{
  background-color: white;
}
.proposal-card {
  .ant-card-body {
    background-color: #F4F5FE;
  }
  .card-content {
    .info-item {
      .value {
        // background: white;
        color: $baseBlack;
        opacity: 0.8;
      }
    }
  }
}

  .pricing-fixed-Table {
    tr:nth-child(even) {

      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #fbfbff !important;

        &:hover {
          background: #fbfbff !important;
        }
      }
    }

    tr:nth-child(odd) {

      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #f4d7e3 !important;

        &:hover {
          background: #f4d7e3 !important;
        }
      }
    }

    tr.ant-table-row:hover:nth-child(odd) {

      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #f4d7e3 !important;
      }
    }

    tr.ant-table-row:hover:nth-child(even) {

      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #fbfbff !important;
      }
    }
  }
  .logDetails_table {
    tr:nth-child(even) {

      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #fbfbff !important;

        &:hover {
          background: #fbfbff !important;
        }
      }
    }

    tr:nth-child(odd) {

      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #f4d7e3 !important;

        &:hover {
          background: #f4d7e3 !important;
        }
      }
    }

    tr.ant-table-row:hover:nth-child(odd) {

      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #f4d7e3 !important;
      }
    }

    tr.ant-table-row:hover:nth-child(even) {

      td.ant-table-cell-fix-left,
      td.ant-table-cell-fix-right {
        background: #fbfbff !important;
      }
    }
  }
  // .logDetails_table {

  //   tr:nth-last-child(odd) {

  //     td.ant-table-cell-fix-left:nth-child(odd),
  //     td.ant-table-cell-fix-left:nth-last-child(odd) {
  //       background: #fbfbff !important;
  //     }
  //   }

  //   tr:nth-last-child(even) {

  //     td.ant-table-cell-fix-left:nth-child(even),
  //     td.ant-table-cell-fix-left:nth-last-child(even) {
  //       background: #f4d7e3 !important;
  //     }
  //   }

  //   .ant-table-tbody tr.ant-table-row:first-child(even) {
  //     background: #fbfbff !important;
  //   }

  //   .ant-table-tbody tr.ant-table-row:first-child(odd) {
  //     background: #f4d7e3 !important;
  //   }

  //   .ant-table-tbody tr.ant-table-row:nth-child(even) {
  //     td:nth-last-child(2) {
  //       background: #fbfbff !important;
  //     }

  //   }

  //   .ant-table-tbody tr.ant-table-row:nth-child(odd) {
  //     td:nth-last-child(2) {
  //       background: #f4d7e3 !important;
  //     }
  //   }

  //   tr:nth-child(even) {

  //     td:first-child,
  //     td:nth-last-child(2) {
  //       border-top-left-radius: 0;
  //       border-bottom-left-radius: 0;
  //       background-color: #fbfbff !important;
  //     }

  //     &:hover>td:first-child,
  //     td:nth-last-child(2) {
  //       background: #fbfbff !important;
  //     }
  //   }

  //   tr:nth-child(odd) {

  //     td:first-child,
  //     td:nth-last-child(2) {
  //       background-color: #f4d7e3 !important;
  //       border-top-left-radius: 0;
  //       border-bottom-left-radius: 0;
  //     }

  //     &:hover>td:first-child,
  //     td:nth-last-child(2) {
  //       background: #f4d7e3 !important;
  //     }
  //   }
  // }

  //raw margin crc pm
  .raw-pagination{
    .ant-pagination-item {
      color: $light-theme-text-color !important;
    }
  }

  //ag grid raw crc pm data table
.raw-pagination {
  color: $baseBlack;
  border: 1px solid #0d0d0d;
}


.pivot-total-value {
  background-color: #d0f5ff; /* Light Gray background */
  font-size: 16px; /* Larger font size */
  font-weight: bold; /* Bold text */
  color:black; /* Black text */
}
//
.ag-theme-material-dark .ag-cell-inline-editing{
  background-color: $baseWhite;
}
.ag-theme-material-dark .ag-text-field-input::placeholder {
  color: $baseBlack
}
.ag-theme-material-dark .ag-column-drop-vertical-empty-message{
  color: $baseBlack;
  opacity: 0.5;
}
//pivot sidebar
.ag-theme-material-dark {
  --ag-side-button-selected-background-color: #CC222244;
  --ag-foreground-color: $baseBlack;
  --ag-header-foreground-color: $baseBlack;
  --ag-secondary-foreground-color: $baseBlack;
  --ag-toggle-button-switch-background-color: 'green';
  --ag-toggle-button-switch-background-color: black;
}

.ag-theme-material-dark .ag-menu {
  background-color: white;
}

.custom-grid-theme {
  --ag-material-primary-color: $baseBlack;
  --ag-foreground-color: $baseBlack;
  --ag-header-foreground-color: $baseBlack;
  // --ag-header-foreground-color: $baseRed !important;
  --ag-odd-row-background-color: hsl(0, 0%, 100%);
  --ag-range-selection-background-color: hsl(0, 0%, 100%);
  --ag-input-focus-box-shadow: hsl(0, 0%, 100%);

  .ag-root-wrapper {
    color: $baseBlack;
  }

  .ag-input-wrapper, .ag-picker-field-wrapper{
    background-color: hsl(0, 0%, 100%);
    color: $baseBlack;
  }

  .ag-input-wrapper input::placeholder {
    color: $baseBlack;
    opacity: 0.7;
  }

  .ag-row-group-panel .ag-row-group-panel-empty-message {
    color: $baseBlack;
  }


  .ag-header-cell-resize{
    background-color: $baseBlack;
  }

  .ag-floating-filter {
    .ag-floating-filter-input {
      input {
        background-color: hsl(0, 0%, 100%);
        color: $baseBlack;
      }
    }
  }

  .ag-row {
    color: $baseBlack;
    background-color: #F4F5FE;
    border-bottom: 1px solid #414041;
  }

  .ag-filter {
    color: $baseBlack; // Filter field text color
  }
}
  
  .logTabel-modal {

    .ant-modal-body {
      padding-bottom: 0 !important;
    }

    .ant-table-thead {
      font-size: $font-size-base;

      tr {
        th {
          background: #f5a1ad !important;
          // background: #ef314c38 !important;
          border-bottom: none !important;
          vertical-align: top;
          white-space: nowrap;
          font-weight: 500 !important;
          color: $baseRed;
          padding: 10px;
          // color: $light-theme-text-color;

          &.ant-table-cell-scrollbar {
            box-shadow: none;
          }
        }
      }
    }

    .ant-table-tbody tr.ant-table-row:nth-child(even) {
      background: #fbfbff !important;
    }

    .ant-table-tbody tr.ant-table-row td {
      padding: 10px;
    }

    .like,
    .unlike {
      border-radius: 25px;
      border: 1px solid;
      padding: 5px;
    }

    .like {
      color: #7eff12;
      background: rgba($baseWhite, 0.6);
    }

    .unlike {
      color: #ff0000;
      background: rgba($baseWhite, 0.6);
    }

    .pending {
      background: transparent;
      border: 1px solid;
    }
  }

  .inspection-logs {
    .log-filter {
      font-size: 20px;
      margin: 0 18px;
      cursor: pointer;
      color: $darkGrey;
    }

    .ant-btn {

      &:hover {
        color: $baseRed !important;
      }

      &:focus,
      &:active {
        color: $baseWhite;
      }
    }
  }

  .ant-input-number {
    background: #f6f7fe;
    border-color: #d9dadf;
    color: #34343e;
  }

  .log-calender {

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
    .log-calender .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
    .log-calender .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
    .log-calender .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
      background: #6c757d;
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
      border-color: #111b26;
      border-top: 2px solid #111b26 !important;
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
      border-top: 2px solid #ef314c;
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .log-calender .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
    .log-calender .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
    .log-calender .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
      color: #ffffffd9;
    }

    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
      color: #34343e;
    }

    .ant-picker-calendar-header {
      background: #ffffff;
    }

    .ant-picker-calendar-full .ant-picker-panel {
      background: #ffffff;
    }

    .ant-radio-button-wrapper {
      color: $light-theme-text-color;
    }
  }

  .heatp-bottom-border{
    border-bottom: 1px solid black;
  }

  .log-calender {
    .events {
      .ant-typography {
        color: white !important;
      }
    }
  }

  .log-select-pipeline {
    .ant-select-arrow {
      color: #34343e;
    }

    .ant-select-selection-placeholder {
      color: #34343e;
    }
  }

  .itp-select {
    .ant-select-arrow {
      color: #EF314C;
    }
  }

  .ant-select-selection-placeholder {
    color: #34343e;
  }


  .no_image {
    color: #d9dadf;
  }

  .delete_station_list_icon {
    background: #dee2e6;
    color: #ef314c;

    &:hover {
      background: #2b2021;
    }
  }

  .calender_header {
    background: #dee2e6;
  }

  .log-table {
    .ant-table-pagination.ant-pagination {
      color: $light-theme-text-color;
    }

    .ant-pagination-prev,
    .ant-pagination-next {

      .anticon-left,
      .anticon-right {
        color: $light-theme-text-color;
      }
    }
  }

  .dated {
    background-color: #dee2e6;
  }

  .ant-picker-cell-disabled::before {
    background: #3030303b;
  }

  .ant-spin-text {
    color: $light-theme-text-color;
  }

  .pipeline-modal {
    .ant-input[disabled] {
      color: $light-theme-text-color;
    }
    .ant-form {
      
        color: $light-theme-text-color;
    
  }
  .ant-checkbox-wrapper{
    color: $light-theme-text-color;
  }
}
.Generate-btn {
  
  .cancel-btn {
    color: #34343e !important;
    color: $light-theme-text-color;
    border: 1px solid  #34343e
   
  }

}

  .user_photo {
    background: #ef314c;
    color: white;
  }

  .change_profile_password {
    color: #ef314c !important;
  }

  .ant-input-password-icon.anticon {
    color: #34343e;

    &:hover {
      color: #34343e !important;
    }
  }


  .team_tabs {

    .btn_left,
    .btn_right {
      color: $light-theme-text-color;
      background: white 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000001a;
      height: 40px;

      &:active,
      &:hover,
      &:focus {
        color: $light-theme-text-color;
      }
    }

    .btnActive {
      color: $baseWhite !important;
      background: #ef314c;
      border: 1px solid #ef314c;
    }

  }

  .equip_table {
    .ant-table-tbody tr:hover {
      background: #dee2e6 !important;

      .edit-hover {
        color: $baseRed !important;
      }
    }

  }

  .equipment_logs {

    .equip_tabs {
      text-align: center;
      margin-bottom: 20px;
      background: #ffffff !important;
      border-radius: 25px;
      padding: 8px;
      box-shadow: 1px 1px 4px 0px #808080b8 !important;
    }

    .ant-btn {
      color: $baseRed;
    }

    .active {
      color: $baseWhite;
      border: 1px solid $baseRed;
      background: $baseRed !important;
    }
  }

  .calenderView {
    .ant-picker {
      border: 1px solid $light-theme-text-color;
    }
  }

  .maintenance-select .bevel-select{
    .ant-select-selector {
      border: 1px solid rgba(112, 112, 112, 0.22) !important;
    }

    .ant-select-selection-overflow {
      padding: 6px 12px;

      .ant-select-selection-item {
        border-radius: 25px;
        color: $light-theme-text-color;
        width: 96%;
        padding: 0px 10px;
        background: $baseWhite;
        border: 1px solid #dee2e6;

        .anticon-close {
          color: $baseRed;
        }
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #dee2e6 !important;
  }

  .ant-select-item-option-state {
    color: $baseBlack;
  }
  .add-new-calibration
  {
    color: $baseBlack;
  }
  .admin {

    .admin_card {
      background: white;
      // border: 1px solid darkgray;
      color: #34343E;
      // font-weight: 600;

      .anticon {
        color: $baseRed;

        &:hover {
          color: $baseWhite;
        }
      }
    }
  }



  .quality-modal{
    .ant-select-arrow,
    .ant-select-selection-item{
      color: $baseBlack;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      border: 1px solid;
      border-color: $light-theme-border-color;
    }
  }
  .quality-modal .heatprofile-modal .toolbox-admin .ant-modal-footer .ant-btn[disabled]{
    color:#34343e9e;
    border-color: #34343e9e;
  }
  
   // user managment
  
   .signup-form{
   
    h1{
      color: $baseBlack;
    }
    .ant-input{
      height: 50px;
      padding: 6.5px 18px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.2);
    }
    .password-icon {
      border: 1px solid rgba(112, 112, 112, 0.22) !important;
      background: rgba(255, 255, 255, 0.2);
      .ant-input {
        border: none !important;
        &:hover, &:focus, &:active {
          border: none !important;
        }
      }
    }
  }
  .user-access-tree{
    color: $light-theme-darkgray;
  }

  .ant-modal-confirm .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-title {
    color: $light-theme-text-color;
  }
 

}


